import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import api from "../../api";
import {
  Popover,
  message,
  Modal,
  Form,
  Input,
  Typography,
  Divider,
  Spin,
} from "antd";
import SubCard from "../SaasLayout/ProductSubscription/SubCard";
import img1 from "../../assets/noProject.png";
import img2 from "../../assets/noProject2.png";
import useUrlState from "@ahooksjs/use-url-state";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useGetState } from "ahooks";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getDeploy,
  getUrlSearch,
  removeUrlSearch,
  setSuperGroup,
  setToken,
  setUserStatus,
} from "../../utils/cookie";
import wendaIng from "../../assets/wenda.png";
import { initUrl } from "../../utils";
import moment from "moment";
const { Paragraph } = Typography;
const { TextArea } = Input;

export default function GuidePage() {
  const [licenseList, setLicenseList, getLicenseList] = useGetState([]);
  const [
    personalSubsPlanList,
    setPersonalSubsPlanList,
    getPersonalSubsPlanList,
  ] = useGetState([]);

  const [state, setState] = useUrlState({});
  const [project, setProject] = useState(null);
  const [isFromLogin, setIsFromLogin] = useState(false);
  const [isInternalSystem, setIsInternalSystem] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [projectList, setProjectList] = useState([]);
  const [choiceProject, setChoiceProject] = useState(null);
  const [choicePlanName, setChoicePlanName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [upLoading, setUpLoading] = useState(false);
  const [wXImg, setWXImg] = useState(null);
  const [form] = Form.useForm();

  const userInfo = useSelector((state) => state.login.userInfo);
  const history = useHistory();

  //获取当前企业的订阅方案
  const getSubsPlan = () => {
    api.getSubsPlan().then((res) => {
      console.log("getSubsPlan", res);
      if (res.data?.code === 0) {
        if ((res.data?.data || [])?.length === 0) {
          if (state.project) {
            message.warning("您还未订阅企业版产品，欢迎您体验或订阅。");
          }
        } else {
          //记录从哪个企业跳过来的,如果有此项目的订阅信息,跳到对应项目首页
          // const item = res.data?.data?.find(
          //   (_) => _?.prd_code === state.project
          // )
          // if (item) {
          //   window.location.href = item?.product_url
          // }
          if (state.fromLogin) {
            let isHas = res.data?.data.some((_) => {
              if (_.prd_code === state.project) {
                return true;
              }
              return _.prd_code?.includes(state.project);
            });
            if (isHas) {
              setIsFromLogin(false);
            }
          }
        }
        setLicenseList(res.data.data || []);
      } else {
        message.error(res.data.msg);
        setLicenseList([]);
      }
    });
  };

  //获取个人版的订阅方案
  const getPersonalSubsPlan = () => {
    api.getPersonalSubsPlan().then((res) => {
      console.log("getPersonalSubsPlan", res);
      if (res.data?.code === 0) {
        setPersonalSubsPlanList(res.data.data || []);
      } else {
        message.error(res.data.msg);
        setPersonalSubsPlanList([]);
      }
    });
  };

  const getProjectListFunc = () => {
    setLoading(true);
    api.getRecommend().then((res) => {
      console.log(res);
      if (res.data.code === 0) {
        const list = Object.keys(res.data?.data || {})
          .map((_) => {
            return {
              key: _,
              ...(res.data?.data[_] || {}),
            };
          })
          ?.sort((a, b) => a.seq - b.seq);
        setProjectList(list);
        let item = list?.at(0);
        if (state.project) {
          let _item = list.find((_) => {
            if (_.key === state.project) {
              return true;
            }
            return _.key?.includes(state.project);
          });
          if (_item) {
            item = _item;
          }
        }
        setChoiceProject(item);
        if (state.jump) {
          setTimeout(() => {
            const targetElement = document.querySelector("#tj");
            targetElement.scrollIntoView({ behavior: "smooth" });
          }, 500);
        }
      } else {
        message.error(res.data.msg);
      }
      setLoading(false);
    });
  };

  //获取二维码
  const getQrFunc = () => {
    api.getQRCodeUrl("wx", "3").then((res) => {
      setWXImg(res.data?.data?.qrcode_url);
    });
  };

  //获取权限列表
  const getMenuList = () => {
    let a = api.getPrdCover();
    let b = api.getMenu();
    Promise.all([a, b]).then((res) => {
      const [res1, res2] = res;
      const prdList = res1?.data?.data || [];
      const menuList = res2?.data?.data || [];
      let item = menuList?.find((_) => _?.menu_code?.includes(project));
      if (item && item?.menu_url) {
        window.location.href = item?.menu_url;
        return;
      }
      setProjectList(prdList);
    });
  };

  useEffect(() => {
    if (getDeploy() === 'BOX') {
      history.push('/')
      return
    }
    //记录从哪个企业跳过来的,如果有此项目的订阅信息,跳到对应项目首页
    const project = state.project;
    //判断是否是某个产品跳转但是又跳转到登录界面后没记录
    if (getUrlSearch()) {
      if (!state.project) {
        let str = getUrlSearch();
        let searchObj = {};
        str?.split("&")?.forEach((_) => {
          let [key, value] = _?.split("=");
          searchObj[key] = value;
        });
        if (searchObj?.project) {
          setState({
            project: searchObj?.project,
          });
        }
      }
      removeUrlSearch();
    }
    setProject(project);
    if ((project === "OPM" || project === "SYS") && state.fromLogin) {
      setIsInternalSystem(true);
      getMenuList();
      return;
    }
    setIsFromLogin(!!state.fromLogin);
    getQrFunc();
    setTimeout(() => {
      getSubsPlan();
      getProjectListFunc();
    }, 100);
  }, []);

  useEffect(() => {
    if (JSON.stringify(userInfo) === "{}") {
      return;
    }
    if (
      userInfo?.co_type_code === "FORMAL_CORP" &&
      !(userInfo?.is_admin || userInfo?.is_creator)
    ) {
      let path = "/saas/noProject";
      if (state.project) {
        path += `?project=${state.project}`;
      }
      history.push(path);
    }
    //不是个人版 要获取下个人版订阅方案
    if (userInfo?.co_type_code !== "INDIV_ORG") {
      getPersonalSubsPlan();
    }
  }, [userInfo]);

  const changeProject = (item) => {
    setChoiceProject(item);
  };
  const initDom = (list, key) => {
    const item = getLicenseList()?.find((_) => _.prd_code === key) || {};
    const _item =
      list?.find((_) => _.subs_plan_id === item?.subs_plan_id) || {};
    //个人版本
    if (userInfo?.co_type_code === "INDIV_ORG") {
      //已订阅个人标准版 隐藏个人试用版
      if (_item.version_type_code === "PERSONAL_EDITION") {
        list = list?.filter(
          (_) => _.version_type_code !== "PERSONAL_TRIAL_EDITION"
        );
      }
    } else {
      //已订阅企业标准版、企业高级版、企业定制版 隐藏企业试用版
      if (
        [
          "ENTERPRISE_EDITION",
          "ENTERPRISE_EDITION",
          "ENTERPRISE_CTO_EDITION",
        ]?.includes(_item.version_type_code)
      ) {
        list = list?.filter(
          (_) => _.version_type_code !== "ENTERPRISE_TRIAL_EDITION"
        );
      }
      //如果订阅过个人版本
      if (getPersonalSubsPlanList()?.length > 0) {
        const personalItem =
          getPersonalSubsPlanList()?.find((_) => _.prd_code === key) || {};
        const _personalItem =
          list?.find((_) => _.subs_plan_id === personalItem?.subs_plan_id) ||
          {};
        //已订阅个人标准版 隐藏个人试用版
        if (_personalItem.version_type_code === "PERSONAL_EDITION") {
          list = list?.filter(
            (_) => _.version_type_code !== "PERSONAL_TRIAL_EDITION"
          );
        }
        list = list?.map((_) => {
          if (_.subs_plan_id === _personalItem?.subs_plan_id) {
            _.isSubscription = true;
          }
          return _;
        });
      }
    }
    list = list?.map((_) => {
      _.arrStr = JSON.stringify(
        (_?.subs_plan_item || [])?.map((__) => ({
          subs_plan_item_type_code: __.subs_plan_item_type_code,
          subs_plan_item_name: __.subs_plan_item_name,
        }))
      );
      return _;
    });
    let KeyObj = {};
    list?.forEach((__) => {
      if (KeyObj[__?.arrStr]) {
        KeyObj[__?.arrStr].push(__);
      } else {
        KeyObj[__?.arrStr] = [__];
      }
    });
    let len = 0;
    Object.keys(KeyObj)?.forEach((key, index) => {
      len = len + 1 + KeyObj[key]?.length;
    });
    const width = `calc(${parseInt(100 / len)}% - 6px)`;
    return (
      <>
        {Object.keys(KeyObj)?.map((key, __index) => {
          return (
            <>
              {__index ? <Divider type="vertical" /> : null}
              <div className="proTableItemBox" style={{ width: width }}>
                <div className="proTableItemTitleBox">
                  <span className="title">功能权益</span>
                </div>
                <div
                  className="proTableItemListBox"
                  style={{ background: "#F9F9F9", color: "#535353" }}
                >
                  {(JSON.parse(key) || [])?.map((item) => (
                    <div>
                      {item.subs_plan_item_type_code === "AUTH_DEADLINE_TIME"
                        ? "授权时间"
                        : item.subs_plan_item_name}
                    </div>
                  ))}
                </div>
              </div>
              {KeyObj[key]?.map((item, index) => (
                <div
                  key={index}
                  className={
                    item.version_type_code?.includes("PERSONAL")
                      ? "proTableItemBox proTableItemBoxFree"
                      : "proTableItemBox"
                  }
                  style={{ width: width }}
                >
                  <div
                    className={
                      item.charge_mode_code === "FREE"
                        ? "proTableItemTitleBox proTableFreeItemTitleBox"
                        : "proTableItemTitleBox"
                    }
                  >
                    {item.charge_mode_code === "FREE" && (
                      <span className="freeSpan">免费</span>
                    )}
                    <div className="proTableItemName">
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          tooltip: item.subs_plan_name,
                        }}
                      >
                        {item.subs_plan_name}
                      </Paragraph>
                    </div>
                    <div className="proTableItemMsg">
                      <Paragraph
                        ellipsis={{
                          rows: 2,
                          tooltip: item.subs_plan_desc,
                        }}
                      >
                        {item.subs_plan_desc}
                      </Paragraph>
                    </div>
                    <div className="proTableItemBtnBox">
                      <span>
                        <span>¥</span>
                        {item.subs_method_code === "FREE_SUBS" ? "0" : "?"}
                      </span>
                      {getLicenseList()?.some(
                        (_) => _.subs_plan_id === item.subs_plan_id
                      ) || item.isSubscription ? (
                        <div className="successBtn">
                          <CheckCircleFilled /> 已订阅
                        </div>
                      ) : item.subs_method_code === "FREE_SUBS" && upLoading ? (
                        <>
                          <LoadingOutlined
                            style={{
                              color: "#FEC054",
                              fontSize: "20px",
                              fontWeight: "bolder",
                            }}
                          />
                        </>
                      ) : (
                        <div
                          className={
                            item.subs_method_code === "FREE_SUBS"
                              ? "freeBtn"
                              : "contactBtn"
                          }
                          onClick={() => {
                            upFunc(item);
                          }}
                        >
                          {item.subs_method_code === "FREE_SUBS"
                            ? "免费订阅"
                            : "联系商务"}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="proTableItemListBox">
                    {(item?.subs_plan_item || [])?.map((_item) => {
                      if (
                        _item.subs_plan_item_type_code === "AUTH_DEADLINE_TIME"
                      ) {
                        return (
                          <div>
                            {item.subs_default_dur + item.subs_dur_unit}
                          </div>
                        );
                      }
                      if (
                        _item?.is_bool !== null &&
                        _item?.is_bool !== undefined
                      ) {
                        return (
                          <div>
                            {_item.is_bool ? (
                              <CheckCircleFilled />
                            ) : (
                              <CloseCircleOutlined />
                            )}
                          </div>
                        );
                      }
                      let str = _item.item_auth_value?.toString();
                      if (item?.subs_plan_name?.includes("定制")) {
                        return (
                          <div>
                            {str === "-1"
                              ? "不限"
                              : str === ""
                              ? "不涉及"
                              : "定制"}
                          </div>
                        );
                      }
                      return (
                        <div>
                          {str === "-1" ? "不限" : str === "" ? "不涉及" : str}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </>
          );
        })}
        {/* <div className="proTableItemBox" style={{ width: width }}>
          <div className="proTableItemTitleBox">
            <span className="title">功能权益</span>
          </div>
          <div
            className="proTableItemListBox"
            style={{ background: "#F9F9F9", color: "#535353" }}
          >
            {(list?.at(0)?.subs_plan_item || [])?.map((item) => (
              <div>
                {item.subs_plan_item_type_code === "AUTH_DEADLINE_TIME"
                  ? "授权时间"
                  : item.subs_plan_item_name}
              </div>
            ))}
          </div>
        </div>
        {list?.map((item, index) => (
          <div
            key={index}
            className={
              item.version_type_code?.includes("PERSONAL")
                ? "proTableItemBox proTableItemBoxFree"
                : "proTableItemBox"
            }
            style={{ width: width }}
          >
            <div
              className={
                item.charge_mode_code === "FREE"
                  ? "proTableItemTitleBox proTableFreeItemTitleBox"
                  : "proTableItemTitleBox"
              }
            >
              {item.charge_mode_code === "FREE" && (
                <span className="freeSpan">免费</span>
              )}
              <div className="proTableItemName">
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    tooltip: item.subs_plan_name,
                  }}
                >
                  {item.subs_plan_name}
                </Paragraph>
              </div>
              <div className="proTableItemMsg">
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    tooltip: item.subs_plan_des,
                  }}
                >
                  {item.subs_plan_des}
                </Paragraph>
              </div>
              <div className="proTableItemBtnBox">
                <span>
                  <span>¥</span>
                  {item.subs_method_code === "FREE_SUBS" ? "0" : "?"}
                </span>
                {getLicenseList()?.some(
                  (_) => _.subs_plan_id === item.subs_plan_id
                ) || item.isSubscription ? (
                  <div className="successBtn">
                    <CheckCircleFilled /> 已订阅
                  </div>
                ) : item.subs_method_code === "FREE_SUBS" && upLoading ? (
                  <>
                    <LoadingOutlined
                      style={{
                        color: "#FEC054",
                        fontSize: "20px",
                        fontWeight: "bolder",
                      }}
                    />
                  </>
                ) : (
                  <div
                    className={
                      item.subs_method_code === "FREE_SUBS"
                        ? "freeBtn"
                        : "contactBtn"
                    }
                    onClick={() => {
                      upFunc(item);
                    }}
                  >
                    {item.subs_method_code === "FREE_SUBS"
                      ? "免费订阅"
                      : "联系商务"}
                  </div>
                )}
              </div>
            </div>
            <div className="proTableItemListBox">
              {(item?.subs_plan_item || [])?.map((_item) => {
                if (_item.subs_plan_item_type_code === "AUTH_DEADLINE_TIME") {
                  return (
                    <div>{item.subs_default_dur + item.subs_dur_unit}</div>
                  );
                }
                if (_item?.is_bool !== null && _item?.is_bool !== undefined) {
                  return (
                    <div>
                      {_item.is_bool ? (
                        <CheckCircleFilled />
                      ) : (
                        <CloseCircleOutlined />
                      )}
                    </div>
                  );
                }
                return (
                  <div>
                    {_item.item_auth_value?.toString() === "-1"
                      ? "不限"
                      : _item.item_auth_value?.toString()}
                  </div>
                );
              })}
            </div>
          </div>
        ))} */}
      </>
    );
  };

  const initHeaderDom = () => {
    let projectObj = {};
    projectList.forEach((_) => {
      if (projectObj[_?.sale_prd_code]) {
        projectObj[_?.sale_prd_code].list?.push(_);
      } else {
        projectObj[_?.sale_prd_code] = {
          logo_url: _.logo_url,
          sale_prd_name: _.sale_prd_name,
          sale_prd_code: _.sale_prd_code,
          list: [_],
          seq: _.seq,
        };
      }
    });
    const keyList = Object.keys(projectObj)?.sort(
      (a, b) => projectObj[a]?.seq - projectObj[b]?.seq
    );
    return (
      <div className="proListBoxContent">
        {keyList?.map((key) => {
          let len = projectObj[key].list?.length;
          if (len >= 2) {
            let isCheck = projectObj[key].list?.some(
              (_) => _.key === choiceProject?.key
            );
            return (
              <div
                key={key}
                className={
                  isCheck ? "proListItems proListItemsActive" : "proListItems"
                }
                style={{
                  width: `${
                    100 / (keyList?.length || 1) > 33
                      ? 33
                      : 100 / (keyList?.length || 1)
                  }%`,
                }}
                onClick={(e) => {
                  if (isCheck) {
                    return;
                  }
                  changeProject(projectObj[key].list?.at(0));
                  e.stopPropagation();
                }}
              >
                <img src={projectObj[key].logo_url} alt="" />
                <div className="saleSolutionNameDiv">
                  {projectObj[key].list?.map((_, index) => (
                    <>
                      {index !== 0 && <div className="line"></div>}
                      <span
                        className={
                          _.key === choiceProject?.key ? "activeSpan" : ""
                        }
                        onClick={(e) => {
                          changeProject(_);
                          e.stopPropagation();
                        }}
                      >
                        {_.sale_solution_name}
                      </span>
                    </>
                  ))}
                </div>
              </div>
            );
          }
          const item = projectObj[key].list?.at(0);
          return (
            <div
              key={key}
              className={
                item.key === choiceProject?.key
                  ? "proListItem proListItemActive"
                  : "proListItem"
              }
              style={{
                width: `${
                  100 / (keyList?.length || 1) > 33
                    ? 33
                    : 100 / (keyList?.length || 1)
                }%`,
              }}
              onClick={() => {
                changeProject(item);
              }}
            >
              <img src={item.logo_url} alt="" />
            </div>
          );
        })}
      </div>
    );
  };

  const upFunc = (item) => {
    if (upLoading) {
      return;
    }
    if (item.subs_method_code === "FREE_SUBS") {
      setUpLoading(true);
      api.setFreeSubscription(item.release_id).then((res) => {
        console.log(res);
        if (res.data?.code === 0) {
          message.success(
            `恭喜您订阅 ${choiceProject?.project_name} ${item?.subs_plan_name} 成功 !`
          );
          setTimeout(() => {
            api.getEnterprise().then((res2) => {
              if (res2?.code === 0 && res2.data?.length >= 1) {
                const co_id = res2.data?.find(
                  (_) => _.co_type_code === "INDIV_ORG"
                )?.co_id;
                api.switchEnterpriseById(co_id).then((res3) => {
                  console.log(res3?.data?.data);
                  if (res3.data.code === 0) {
                    setToken(res3?.data?.data?.token || "");
                    setSuperGroup(res3?.data?.data?.super_group || false);
                    setUserStatus("0");
                    // window.location.href = window.location.origin;
                    setState({
                      jump: undefined,
                    });
                    window.location.reload();
                  } else {
                    message.error(res?.data?.msg || "切换失败");
                  }
                });
              } else {
                message.error(res.msg);
              }
            });
          }, 1000);
        } else {
          message.error(res.data.msg);
        }
        setUpLoading(false);
      });
    } else {
      setIsModalOpen(true);
      setChoicePlanName(item.subs_plan_name);
      let name = userInfo?.co_name || null;
      if (userInfo?.co_type_code === "INDIV_ORG") {
        name = null;
      }
      form.setFieldsValue({
        enterpriseName: name,
        name: userInfo?.full_name || null,
        phone: userInfo?.mobile_no || null,
        demand: null,
      });
    }
  };

  const onFinish = () => {
    form.validateFields().then((res) => {
      let obj = {
        project_code: choiceProject?.key,
        subs_plan_name: choicePlanName,
        user_id: userInfo?.individual_id,
      };
      const params = {
        co_name: res.enterpriseName, //企业名称
        full_name: res.name, //姓名
        mobile: res.phone, //手机号
        remark: res.demand, //备注
        extra_info: JSON.stringify(obj),
      };
      api.upContact(params).then((res2) => {
        console.log(res);
        if (res2.data?.code === 0) {
          setIsModalOpen(false);
          message.success("邮件已发送，7天内商务会主动联系您，请耐心等待！");
        } else {
          message.error(res2.data?.msg);
        }
      });
    });
  };

  return (
    <div className={styles["root"]} loading={true}>
      {isInternalSystem ? (
        <div className="concent">
          <div className="contentDiv">
            <div className="contentBox">
              <div className="userMsgBox">
                <img src={img1} alt="" />
                <div className="userMsg">
                  <div className="userMsgTitle">
                    当前未加入管理团队，无访问{" "}
                    <span style={{ color: "#0068B2" }}>
                      {
                        projectList?.find((_) => _.prd_code?.includes(project))
                          ?.prd_name
                      }
                    </span>{" "}
                    权限！
                  </div>
                  <div className="userMsgText">请联系管理员开通权限</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="concent">
          <Spin spinning={projectList.length === 0}>
            <div className="contentDiv">
              {projectList.length === 0 ? null : (
                <div className="contentBox">
                  {licenseList?.length > 0 ? (
                    isFromLogin &&
                    projectList?.some((_) => _.key?.includes(state.project)) ? (
                      <div className="userMsgBoxContent">
                        <div className="userMsgBox">
                          <img src={img2} alt="" />
                          <div className="userMsg">
                            <div className="userMsgTitle">
                              当前还未订阅{" "}
                              <span>
                                {
                                  projectList?.find((_) =>
                                    _.key?.includes(state.project)
                                  )?.sale_prd_name
                                }
                              </span>{" "}
                              产品!
                            </div>
                            <div className="userMsgText">
                              请先开通产品订阅 或 访问其他产品
                            </div>
                          </div>
                        </div>
                        <div className="productTitle">已订阅</div>
                        <div className="licenseListBox2">
                          {licenseList?.map((_) => {
                            let type = _.subs_plan_name;
                            let prd = _.prd_name;
                            if (
                              _.subs_plan_name?.includes("（") &&
                              _.subs_plan_name?.includes("）")
                            ) {
                              let [a, b] = _.subs_plan_name?.split("（");
                              type = a;
                              prd = b?.slice(0, -1);
                            }
                            return (
                              <div
                                className="licenseItemBox"
                                style={{ width: "130px" }}
                                onClick={() => {
                                  window.open(_?.product_url, _?.prd_code);
                                }}
                              >
                                <svg
                                  fill="none"
                                  version="1.1"
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  className="topIcon"
                                >
                                  <defs>
                                    <linearGradient
                                      x1="0"
                                      y1="1"
                                      x2="1.0152994394302368"
                                      y2="0.3041720986366272"
                                      id="master_svg0_547_04143"
                                    >
                                      <stop
                                        offset="0%"
                                        stop-color="#FF91A6"
                                        stop-opacity="1"
                                      />
                                      <stop
                                        offset="100%"
                                        stop-color="#FFC683"
                                        stop-opacity="1"
                                      />
                                    </linearGradient>
                                  </defs>
                                  <g>
                                    <g>
                                      <path
                                        d="M0 0C0 0 0 0 0 0L13 0C15.209138999323173 0 17 1.7908610006768266 17 4L17 16C17 16 17 16 17 16L4 16C1.7908610006768266 16 0 14.209138999323173 0 12Z"
                                        fill="currentColor"
                                        fill-opacity="1"
                                      />
                                    </g>
                                    <g>
                                      <g>
                                        <path
                                          d="M7.379160000000001,4.00011728L7.34148,4.00011728C7.02375,4.00011728,6.81088,4.00011728,6.606809999999999,4.0493473C6.51447,4.0712965,6.4241600000000005,4.100731,6.3368,4.137346C6.1434,4.218576,5.9776299999999996,4.343497,5.73525,4.526879L5.7057400000000005,4.549032L5.69192,4.558878C5.25426,4.889335,4.907019,5.15149,4.652082,5.38594C4.391493,5.62594,4.199349,5.86101,4.098253,6.1490100000000005C4.0155493,6.38408,3.9845204,6.633649999999999,4.00720399,6.88131C4.0348326,7.182840000000001,4.165441,7.4536,4.361981,7.74037C4.553498,8.02098,4.827901,8.3459,5.17263,8.75451L6.87179,10.76494C7.17948,11.12986,7.43316,11.429549999999999,7.666119999999999,11.63508C7.91038,11.85046,8.17222,12,8.5,12C8.82778,12,9.08962,11.85108,9.33388,11.63508C9.566220000000001,11.429549999999999,9.8199,11.12986,10.12821,10.76494L11.826740000000001,8.75451C12.1721,8.3459,12.4465,8.02098,12.63802,7.74037C12.83456,7.453609999999999,12.96454,7.183450000000001,12.9928,6.8806899999999995C13.01548,6.63303,12.98445,6.38347,12.90175,6.14839C12.80128,5.86101,12.60851,5.62532,12.34792,5.38594C12.09298,5.15149,11.74511,4.889335,11.30808,4.558879L11.294260000000001,4.549033L11.26475,4.526879C11.021740000000001,4.343497,10.8566,4.218576,10.6632,4.137346C10.575859999999999,4.100526,10.48554,4.0708863,10.39319,4.0487322C10.189119999999999,3.999502148,9.97625,3.999502148,9.65852,4.000117561L7.379160000000001,4.00011728ZM7.66298,6.2567L9.337019999999999,6.2567C9.51042,6.2567,9.65099,6.3944600000000005,9.65099,6.5643899999999995C9.65099,6.73432,9.51042,6.87207,9.337019999999999,6.87208L7.66298,6.87208C7.48958,6.87207,7.349019999999999,6.73432,7.349019999999999,6.5643899999999995C7.349019999999999,6.3944600000000005,7.48958,6.2567,7.66298,6.2567Z"
                                          fill="#FFFFFF"
                                          fill-opacity="1"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                                <div className="licenseType">{prd}</div>
                                <div className="licenseVersion">{type}</div>
                                <div className="licenseLine"></div>
                                <div className="licenseDate">
                                  授权至
                                  {moment(_.license_deadline_time).format(
                                    "YYYY/MM/DD"
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="productTitle">已订阅</div>
                        <div className="licenseListBox">
                          {licenseList?.map((item, index) => (
                            <SubCard
                              max={6}
                              deleteCallBack={getSubsPlan}
                              // deleteIcon={true}
                              // noBtn={true}
                              btnCallBack={(info) => {
                                window.open(info?.product_url, "_new");
                              }}
                              btnText="开始体验"
                              style={{ height: "100%" }}
                              key={index}
                              info={item}
                            ></SubCard>
                          ))}
                        </div>
                      </>
                    )
                  ) : (
                    <div className="userMsgBox">
                      <img src={img2} alt="" />
                      <div className="userMsg">
                        <div className="userMsgTitle">
                          您现在可以开始免费体验或订阅我们的产品服务啦!
                        </div>
                        <div className="userMsgText">
                          如果您想加入同事已经创建的企业或组织
                          <Popover
                            placement="bottomLeft"
                            content={
                              <div>
                                <div
                                  style={{
                                    marginBottom: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  如何加入企业或组织?
                                </div>
                                <span>
                                  您可以联系管理员将邀请二维码或链接发送给您，扫描二维码或者打开链接申请加入。
                                </span>
                              </div>
                            }
                            overlayClassName="myCardPopover"
                          >
                            <span>如何加入</span>
                          </Popover>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className="productTitle"
                    // style={{ marginBottom: "20px" }}
                    id="tj"
                  >
                    为您推荐
                  </div>
                </div>
              )}
            </div>
            {loading ? null : (
              <>
                <div className="proListBox">{initHeaderDom()}</div>
                <div className="proTableBox">
                  <div className="proTable">
                    {initDom(choiceProject?.list, choiceProject?.key)}
                  </div>
                </div>
              </>
            )}
          </Spin>
          <Modal
            visible={isModalOpen}
            centered={true}
            footer={null}
            onCancel={() => {
              setIsModalOpen(false);
            }}
            wrapClassName="commModal"
            width={1000}
          >
            <div className="commModalContent">
              <div className="commModalLeft">
                <div className="titleBox">在线沟通</div>
                <div className="commModalLeftContent">
                  <span>企业微信</span>
                  <img className="qr" src={wXImg} alt="" />
                  <span>在线客服</span>
                  <img
                    className="kf"
                    src={wendaIng}
                    alt=""
                    onClick={() => {
                      let href = initUrl(
                        "https://help.touzhiwang.com/service",
                        choiceProject?.project_name,
                        userInfo
                      );
                      window.open(href, "up");
                    }}
                  />
                </div>
                <div className="userIdBox">
                  您的用户ID：{userInfo?.individual_id}
                </div>
              </div>
              <div className="commModalRight">
                <div className="titleBox">留下联系方式，由我们联系您</div>
                <div className="commModalRightContent">
                  <Form
                    form={form}
                    style={{
                      width: "100%",
                    }}
                    layout="vertical"
                  >
                    <Form.Item
                      name="enterpriseName"
                      label="企业或组织名称"
                      rules={[
                        {
                          required: true,
                          message: "请输入企业或组织名称",
                        },
                      ]}
                    >
                      <Input placeholder="请输入企业或组织名称" />
                    </Form.Item>
                    <Form.Item
                      name="name"
                      label="姓名"
                      rules={[
                        {
                          required: true,
                          message: "请输入姓名",
                        },
                      ]}
                    >
                      <Input placeholder="请输入姓名" />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="手机号"
                      rules={[
                        {
                          required: true,
                          message: "请输入手机号",
                        },
                        {
                          pattern:
                            /^(13\d|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18\d|19[0-35-9])\d{8}$/,
                          message: "请输入正确的手机号",
                        },
                      ]}
                    >
                      <Input placeholder="请输入手机号" />
                    </Form.Item>
                    <Form.Item
                      name="demand"
                      label="您希望的试用或订阅的产品及需求"
                    >
                      <TextArea
                        style={{
                          height: 80,
                          resize: "none",
                        }}
                      />
                    </Form.Item>
                  </Form>
                  <div className="formBtn" onClick={onFinish}>
                    提交
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}
